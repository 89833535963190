import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, isOnListing, hasWhatsNew, isBlogPost, isHomepage, isLeadFullWidth }) => ({
    [theme.breakpoints.up('1220')]: {
        display: hasWhatsNew ? 'flex' : '',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },

    '.title': {
        fontSize: isHomepage ? toRem(32) : toRem(36),
        lineHeight: toRem(40),
        fontWeight: isHomepage ? 200 : 600,

        [theme.breakpoints.up('md')]: {
            fontSize: isHomepage ? toRem(55) : toRem(48),
            lineHeight: toRem(50)
        },

        [theme.breakpoints.up('lg')]: {
            fontSize: isHomepage ? toRem(70) : toRem(50),
            lineHeight: 1,
            maxWidth: isOnListing || isHomepage ? 400 : '100%'
        },

        [theme.breakpoints.up('xl')]: {
            maxWidth: isHomepage ? 600 : '100%'
        },

        [theme.breakpoints.up('xxl')]: {
            maxWidth: isHomepage ? 600 : '100%',
            fontSize: isHomepage ? toRem(90) : toRem(50)
        },

        br: {
            display: 'none',

            [theme.breakpoints.up('lg')]: {
                display: 'block'
            },

            [theme.breakpoints.up('xxl')]: {
                display: 'none'
            }
        }
    },

    '.subtitle': {
        color: isHomepage ? theme.colors.white : theme.colors.goldDarker,
        fontSize: isBlogPost || isHomepage ? toRem(16) : toRem(24),
        lineHeight: 1.5,
        marginTop: toRem(20),
        marginBottom: isHomepage ? 30 : 10,

        p: {
            fontSize: isBlogPost || isHomepage ? toRem(20) : toRem(24),
            lineHeight: toRem(28),
            fontWeight: 500,
            margin: 0
        },

        [theme.breakpoints.up('lg')]: {
            fontSize: isHomepage ? toRem(20) : toRem(35),
            lineHeight: 1.4,
            marginTop: toRem(48),
            maxWidth: isHomepage ? 500 : '100%',

            p: {
                fontSize: toRem(35),
                lineHeight: toRem(40)
            }
        }
    },

    ' .lead': {
        marginTop: toRem(20),
        [theme.breakpoints.up('lg')]: {
            maxWidth: isLeadFullWidth ? '100%' : 650
        }
    },

    '& .WhatsNewButton': {
        display: 'none',
        alignItems: 'center',
        fontWeight: 600,
        marginTop: toRem(40),
        color: theme.colors.goldDark,

        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },

        [theme.breakpoints.up('1220')]: {
            marginTop: 0,
            marginRight: -20,
            marginBottom: toRem(15)
        },

        svg: {
            marginRight: 10,
            animationName: 'scaleTitle',
            animationDuration: '3s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease-in-out',

            path: {
                animationName: 'blinkTitle',
                animationDuration: '3s',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'ease-in-out'
            }
        },

        '@keyframes scaleTitle': {
            '0%': { transform: 'scale(1,1)' },
            '50%': { transform: 'scale(1.3, 1.3)' },
            '100%': { transform: 'scale(1,1)' }
        },

        '@keyframes blinkTitle': {
            '0%': { fill: theme.colors.goldDarker },
            '50%': { fill: theme.colors.white },
            '100%': { fill: theme.colors.goldDarker }
        }
    }
})

export default style
